import { useState, useEffect, useMemo } from "react";
// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
// Santanu Garnaik Design React components
import MDBox from "components/MDBox";
// Santanu Garnaik Design React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
// Santanu Garnaik Design React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
// Santanu Garnaik Design React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import routes from "routes";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
// Images
import brandWhite from "assets/images/logo-light.svg";
import brandDark from "assets/images/logo-dark.svg";
const logo = "https://eduskills.academy/images/home/academy%20logo.png";
import { nonAuthRoutes } from "routes";
import { studentRoutes } from "routes";
import { courseModule } from "routes";
import { useSelector } from "react-redux";
import { selectStudentModule } from "store/Slices/appSlice";
import { selectRoutes } from "store/Slices/appSlice";
import { jwtDecode } from "jwt-decode";
// //import jwtDecode from "jwt-decode";
// const auth = localStorage.getItem("accessToken");

// const userType = localStorage.getItem("userType");
const studentModule = false;
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  // const studentModule = useSelector(selectStudentModule);
  // const route = useSelector(selectRoutes);
  const module = useSelector(selectStudentModule);
  const routeModule = useSelector(selectRoutes);
  const [authRole, setAuthRole] = useState(null);
  const [auth, setAuth] = useState(null);
  const [userType, setUserType] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get("accessToken");
    const userTypeFromUrl = params.get("userType");

    if (tokenFromUrl && userTypeFromUrl) {
      localStorage.setItem("accessToken", tokenFromUrl);
      localStorage.setItem("userType", userTypeFromUrl);
      setAuth(tokenFromUrl);
      setUserType(userTypeFromUrl);
      navigate("/dashboard", { replace: true });
    }

    // If URL params don't exist, try to get from localStorage
    if (!tokenFromUrl) {
      const storedToken = localStorage.getItem("accessToken");
      setAuth(storedToken);
    }

    if (!userTypeFromUrl) {
      const storedUserType = localStorage.getItem("userType");
      setUserType(storedUserType);
    }
  }, []);
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const getRoleFromToken = () => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      const decodedTokens = jwtDecode(token);
      // console.log(decodedTokens.data.role_code);
      setAuthRole(decodedTokens.data.role_code);
      return decodedTokens.role; // Assuming the role is stored in the JWT
    }
    return null;
  };

  useEffect(() => {
    getRoleFromToken();
  }, []);
  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? logo : logo}
              brandName="EduSkills"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              onModuleSelect={() => ""}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {auth ? getRoutes(routes) : getRoutes(nonAuthRoutes)}
          <Route path="*" element={<Navigate to={auth ? "/dashboard" : "/sign-in"} />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : userType === "USER" && authRole === 2 ? (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? logo : logo}
            brandName="EduSkills"
            routes={studentModule ? courseModule : studentRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onModuleSelect={() => ""}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {auth ? getRoutes(module ? routeModule : studentRoutes) : getRoutes(nonAuthRoutes)}
        <Route element={<Navigate to={auth ? "/dashboard" : "/sign-in"} />} />
      </Routes>
    </ThemeProvider>
  ) : // <ThemeProvider theme={darkMode ? themeDark : theme}>
  //   <CssBaseline />
  //   <Typography>Ok</Typography>
  // </ThemeProvider>
  userType === "ADMIN" && authRole === 1 ? (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? logo : logo}
            brandName="EduSkills"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onModuleSelect={() => ""}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {auth ? getRoutes(routes) : getRoutes(nonAuthRoutes)}
        <Route path="*" element={<Navigate to={auth ? "/dashboard" : "/sign-in"} />} />
      </Routes>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />

      <Routes>
        {getRoutes(nonAuthRoutes)}
        <Route path="*" element={<Navigate to={"/sign-in"} />} />
      </Routes>
    </ThemeProvider>
  );
}
