import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  useMediaQuery,
  Paper,
  Card,
  CardMedia,
  CardContent,
  useTheme,
  Typography,
  Dialog,
  IconButton,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { UserService } from "services/service";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { ContentSection, StyledSection } from "./EditSection";

const SplitDescription = ({ description }) => {
  const containsHTML = /<[^>]*>/g.test(description);

  if (containsHTML) {
    return (
      <Box sx={{ "& > *": { mb: 2 } }}>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Box>
    );
  }

  const sentences = description
    .split(".")
    .filter((s) => s.trim())
    .map((s) => s.trim());

  return sentences.length <= 1 ? (
    <Typography variant="body1" color="text.secondary">
      {description}
    </Typography>
  ) : (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
      {sentences.map((sentence, i) => (
        <Box key={i} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" sx={{ mr: 1, color: "primary.main" }}>
            •
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 500, color: "text.secondary" }}>
            {sentence}.
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const ResponsiveImage = ({ section, isSmallScreen }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const theme = useTheme();

  return (
    <>
      <Box sx={{ position: "relative", mt: 2, mb: 2 }}>
        <CardMedia
          component="img"
          image={section.image}
          alt={section.title}
          sx={{
            borderRadius: 1,
            maxHeight: isSmallScreen ? 200 : 300,
            width: "100%",
            objectFit: "contain",
            bgcolor: "background.paper",
          }}
        />
        <IconButton
          onClick={() => setIsDialogOpen(true)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            bgcolor: "rgba(255, 255, 255, 0.8)",
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.9)",
            },
          }}
        >
          <ZoomInIcon />
        </IconButton>
      </Box>
      {section.caption && (
        <Typography
          variant="caption"
          align="center"
          sx={{
            display: "block",
            mt: 1,
            mb: 2,
            color: theme.palette.text.secondary,
          }}
        >
          {section.caption}
        </Typography>
      )}

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="lg" fullWidth>
        <DialogContent sx={{ position: "relative", p: 0, overflow: "hidden" }}>
          <IconButton
            onClick={() => setIsDialogOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              bgcolor: "rgba(255, 255, 255, 0.8)",
              zIndex: 1,
              "&:hover": {
                bgcolor: "rgba(255, 255, 255, 0.9)",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={section.image}
            alt={section.title}
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "90vh",
              objectFit: "contain",
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const ModuleViewer = ({
  moduleDocument,
  title,
  description,
  setCurrentIndex,
  isCompleted,
  courseCode,
  moduleCode,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [timer, setTimer] = useState(20);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [completedPages, setCompletedPages] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const contentRef = useRef(null);

  const storageKey = `${courseCode}_${moduleCode}_completedPages`;
  const totalPages = moduleDocument.totalPages;
  const currentContent = moduleDocument.pages[currentPage - 1];

  useEffect(() => {
    const storedCompletedPages = JSON.parse(localStorage.getItem(storageKey) || "[]");
    setCompletedPages(storedCompletedPages);
  }, [storageKey]);

  useEffect(() => {
    if (completedPages.length > 0) {
      localStorage.setItem(storageKey, JSON.stringify(completedPages));
    }
  }, [completedPages, storageKey]);

  useEffect(() => {
    let timerInterval;
    if (isTimerRunning) {
      timerInterval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(timerInterval);
            setIsTimerRunning(false);
            setCompletedPages((prev) => {
              const newCompletedPages = [...prev, currentPage];
              localStorage.setItem(storageKey, JSON.stringify(newCompletedPages));
              return newCompletedPages;
            });
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timerInterval);
  }, [isTimerRunning, currentPage, storageKey]);

  useEffect(() => {
    if (currentPage <= totalPages && !completedPages.includes(currentPage)) {
      setTimer(10);
      setIsTimerRunning(true);
    } else {
      setTimer(0);
      setIsTimerRunning(false);
    }
  }, [currentPage, totalPages, completedPages]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [currentPage]);

  const handleNextPage = async () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    } else if (isCompleted) {
      setCurrentIndex((prev) => prev + 1);
    } else if (moduleCode && courseCode) {
      try {
        setLoading(true);
        await UserService.progress({
          courseCode,
          moduleCode,
          isDocumentComplete: 1,
          isVideoComplete: 1,
          isQuestionComplete: 0,
          isFinalQuestionComplete: 0,
          record_status: 1,
          opType: "UPDATE_PROGRESS",
        });
        setCurrentIndex((prev) => prev + 1);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const shouldRenderImage = (section) => {
    if (!section?.image) return false;
    const isBase64 = /^data:image\/[a-z]+;base64,/.test(section.image);
    const isURL = /^https?:\/\/.+/.test(section.image);
    return isBase64 || isURL;
  };

  return (
    <MDBox
      py={0}
      px={isSmallScreen ? 1 : 3}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          height: "calc(100vh - 100px)",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          overflow: "hidden",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            bgcolor: theme.palette.background.paper,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              p: 2,
              borderBottom: `1px solid ${theme.palette.divider}`,
              bgcolor: "#1A73E8",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <Typography
              variant={isSmallScreen ? "h6" : "h5"}
              align="center"
              sx={{
                fontWeight: 500,
                color: "#ffffff",
                fontSize: isSmallScreen ? "1.1rem" : "1.5rem",
              }}
            >
              {currentContent.title}
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              sx={{
                fontWeight: 400,
                color: "#ffffff",
                fontSize: isSmallScreen ? "0.875rem" : "1rem",
              }}
            >
              {currentContent.subtitle}
            </Typography>
          </Box>

          <Box
            ref={contentRef}
            sx={{
              flex: 1,
              overflowY: "auto",
              overflowX: "hidden",
              p: isSmallScreen ? 2 : 3,
              "&::-webkit-scrollbar": { width: "6px", height: "0px" },
              "&::-webkit-scrollbar-track": { bgcolor: "background.paper" },
              "&::-webkit-scrollbar-thumb": {
                bgcolor: "#1A73E8",
                borderRadius: "3px",
              },
            }}
          >
            <ContentSection content={currentContent.content} />

            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
              {currentContent.sections.map((section, index) => (
                <Card
                  key={index}
                  elevation={1}
                  sx={{
                    border: `1px solid ${theme.palette.divider}`,
                    transition: "transform 0.2s ease-in-out",
                    "&:hover": { transform: "translateY(-2px)" },
                  }}
                >
                  <CardContent
                    sx={{
                      p: isSmallScreen ? 2 : 5,
                      "&:last-child": { pb: isSmallScreen ? 2 : 5 },
                    }}
                  >
                    <StyledSection section={section} />

                    {shouldRenderImage(section) && (
                      <ResponsiveImage section={section} isSmallScreen={isSmallScreen} />
                    )}
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>
        </Paper>

        <Box
          sx={{
            mt: "auto",
            position: "sticky",
            bottom: 0,
            bgcolor: theme.palette.background.default,
            pt: 1,
            pb: isSmallScreen ? 2 : 1,
          }}
        >
          <Box sx={{ display: "flex", gap: 0.5, mb: 2 }}>
            {Array.from({ length: totalPages }, (_, index) => (
              <Box
                key={index}
                sx={{
                  flex: 1,
                  height: 4,
                  bgcolor:
                    currentPage > index ? theme.palette.success.main : theme.palette.grey[300],
                  borderRadius: 1,
                  transition: "background-color 0.3s ease",
                }}
              />
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: isSmallScreen ? 1 : 2,
              flexWrap: isSmallScreen ? "wrap" : "nowrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: isSmallScreen ? "100%" : "auto",
                justifyContent: isSmallScreen ? "center" : "flex-start",
                mb: isSmallScreen ? 1 : 0,
              }}
            >
              {isTimerRunning && (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <CircularProgress
                    variant="determinate"
                    color="info"
                    value={(timer / 10) * 100}
                    size={24}
                  />
                  <Typography variant="body2">{timer}s</Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                width: isSmallScreen ? "100%" : "auto",
                justifyContent: isSmallScreen ? "space-between" : "flex-end",
              }}
            >
              <MDButton
                size="small"
                color="info"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                fullWidth={isSmallScreen}
              >
                Previous
              </MDButton>
              <MDButton
                size="small"
                color="info"
                onClick={handleNextPage}
                disabled={loading || (isTimerRunning && !completedPages.includes(currentPage))}
                fullWidth={isSmallScreen}
              >
                {loading ? "Loading..." : currentPage < totalPages ? "Next" : "Complete"}
              </MDButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </MDBox>
  );
};

ModuleViewer.propTypes = {
  moduleDocument: PropTypes.shape({
    totalPages: PropTypes.number.isRequired,
    pages: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        sections: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            image: PropTypes.string,
            caption: PropTypes.string,
          })
        ).isRequired,
      })
    ).isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isCompleted: PropTypes.any,
  courseCode: PropTypes.number.isRequired,
  moduleCode: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
};

SplitDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default ModuleViewer;
