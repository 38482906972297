import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// Santanu Garnaik Design React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpg";
import { AuthService } from "services/service";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../../services/configUrls";
const logo = "https://eduskills.academy/images/home/academy%20logo.png";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [isSendOtp, setIsSendOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const Email = localStorage.getItem("email");
  const navigation = useNavigate();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [captchaText, setCaptchaText] = useState("");
  const [captchaInput, setCaptchaInput] = useState("");
  const [captchaSvg, setCaptchaSvg] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  useEffect(() => {
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL}/auth/getCaptcha`);
      setCaptchaText(response.data?.responseData?.text);
      setCaptchaSvg(response.data?.responseData?.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching CAPTCHA:", error);
      setIsLoading(false);
    }
  };
  function handleSuccessMessage(message) {
    toast.success(message, {
      autoClose: 2000,
      position: "top-center",
    });
  }

  function handleErrMessage(message) {
    toast.error(message, {
      autoClose: 2000,
      position: "top-center",
    });
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleCaptchaInputChange = (event) => {
    setCaptchaInput(event.target.value);
    setIsCaptchaVerified(event.target.value === captchaText);
  };

  const sendOtp = async () => {
    try {
      setIsLoading(true);
      const opType = "SEND_OTP";
      const res = await AuthService.login(email, opType);
      localStorage.setItem("email", email);
      setIsSendOtp(true);
      handleSuccessMessage(res.data?.message);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      handleErrMessage(error.response?.data?.message);
      setIsLoading(false);
    }
  };

  const verifyOtp = async () => {
    try {
      setIsLoading(true);
      const opType = "VERIFY_OTP";
      const res = await AuthService.loginVerify(Email, otp, opType);

      const token = res.data?.responseData?.data;
      const role = res.data?.responseData?.ROLE;

      if (token && role) {
        localStorage.setItem("accessToken", token);
        localStorage.setItem("userType", role);
        handleSuccessMessage(res.data?.message);
        if (role === "USER") {
          navigation("/dashboard");
        } else {
          navigation("/dashboard");
        }
        // navigation("/dashboard");
        window.location.reload();
      } else {
        console.error("Token or role not found in response");
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
      handleErrMessage(error.response?.data?.message);
      localStorage.removeItem("email");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="light"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <img src={logo} alt="logo" height={38} style={{ marginBottom: -8 }} />
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                value={email}
                onChange={handleEmailChange}
                disabled={isLoading || isSendOtp}
                fullWidth
              />
            </MDBox>
            {!isSendOtp && (
              <>
                <MDBox mb={2}>
                  <div dangerouslySetInnerHTML={{ __html: captchaSvg }} />
                  <MDInput
                    type="text"
                    label="Enter CAPTCHA"
                    value={captchaInput}
                    onChange={handleCaptchaInputChange}
                    disabled={isLoading}
                    fullWidth
                  />
                </MDBox>
              </>
            )}
            {isSendOtp && (
              <MDBox mb={2}>
                <MDInput
                  type="otp"
                  label="OTP"
                  value={otp}
                  onChange={handleOtpChange}
                  disabled={isLoading || !isSendOtp}
                  fullWidth
                />
              </MDBox>
            )}

            {isSendOtp && (
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                disabled={isLoading || !email || (!isSendOtp && !isCaptchaVerified)}
                onClick={isSendOtp ? verifyOtp : sendOtp}
                fullWidth
              >
                {isSendOtp ? "Submit" : "Send Otp"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
